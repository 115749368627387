<!--主题基金-->
<template>
  <section class="theme-fund">
    <fund-nav />
    <site-nav>
      <span>&gt;</span>
      <router-link :to="{name:'FundMarket'}">基金超市</router-link>
      <span>&gt;</span>
      <span>主题基金</span>
    </site-nav>
    <div class="vw list-box">
      <div class="theme-block" v-for="theme in themeList" :key="theme.themeId">
        <div class="theme-title flex-between">
          <div>
            <span class="icon iconfont icon-a-fundmarket-theme"></span>
            <b>{{theme.themeName}}</b>
          </div>
          <div class="more">
            <router-link :to="{name:'TopicFunds',params:{themeId:theme.themeId}}" class="btn gray">更多></router-link>
          </div>
        </div>
        <div class="theme-content flex-between">
          <router-link class="item" v-for="fund in theme.funds" :key="fund.fundCode" :to="{name:'FundDetail',params:{fundCode:fund.fundCode,fundType:fund.ofundType}}">
            <div class="title"><span class="name">{{ fund.fundName}}</span></div>
            <div class="code gray">{{fund.fundCode}}</div>
            <div class="type">{{fund.fundType}}</div>
            <div :class="['rate',fund.incomeYearNum < 0 ? 'green':'red']">{{fund.incomeYear}}<small>%</small></div>
            <div class="rate-txt">近一年收益</div>
            <div class="buy-box">
              <a @click.prevent="buyFund(fund)" :class="['btn btn-buy', {'disable gray':fund.buyStatus === '0'}]">立即{{fund.buyTxt}}</a>
              <span :class="['icon iconfont icon-mine-dropdown', {'disable gray':fund.buyStatus === '0'}]"></span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="view-all">
        <router-link :to="{name:'TopicFunds'}" class="btn go-all">查看全部主题基金</router-link>
      </div>
    </div>
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
  </section>
</template>


<script>
import FundNav from '@/views/fund-market/components/FundNav.vue'
import SiteNav from '@/views/fund-market/components/SiteNav.vue'
import DinDetails from '@/components/Models.vue'
import {getDicts} from '@/api/dict.js'
import {themeList,fundList} from '@/api/fund.js'
export default {
  name: "TopicFund",
  components: {
    FundNav,SiteNav,DinDetails
  },
  data() {
    return {
      fundOrderCats: [],
      themeList: [],
      
      showBuyConfirm: false,
      buyConfirmTxt: '',
      buyConfirmStep: 0,
      preBuyFund:null
    }
  },
  created() {
    this.getFundType().then(() => {
      this.topicFund()
    })
  },
  methods: {
		/** 获取基金类型 */
		getFundType() {
			return getDicts('sys_fund_type').then(result => {
				if(!result.data) {
					return
				}
				this.fundOrderCats = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
			})
		},
    /** 获取主题基金 */
    topicFund(){
      this.themeList = []
      themeList(1,5,'1','1','1').then(result => {
        let themes = result.data.data
        themes.forEach(theme => {
          fundList({
            pageNum: 1,
            pageSize: 4,
            customerAppTheme: theme.themeId,
            customerAppThemeTop: '1',
            changeYear: -1,
            privateFlag: '0'
          }).then(result2 => {
            let funds = result2.data.data
            funds.forEach(fund => {
              fund.fundType = (this.fundOrderCats.find(n => fund.ofundType === n.code)||{}).name
              fund.ofundRisklevel = parseInt(fund.ofundRisklevel)
              fund.incomeYear = fund.incomeYear.$trimZero(2)
              fund.incomeYearNum = parseFloat(fund.incomeYear)
              fund.buyTxt = fund.fundStatus === '1' ? '认购' : '买入'
              fund.newfund = fund.fundStatus === '1' ? '1' : ''
            })
            theme.funds = funds
            this.themeList.push(theme)
          })
        })
      })
    },
    /** 购买基金 */
		buyFund(fund) {
      if(fund.buyStatus === '0') {
        return;
      }
      this.buyConfirmStep = 0
      let token = this.$store.getters.token;
      let _userInfo = localStorage.getItem("customerInfo");
      if (!token || !_userInfo) {
        this.buyConfirmTxt = '请登录!'
        this.buyConfirmStep = 1
      }else {
        let userInfo = JSON.parse(_userInfo);
        if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
          this.buyConfirmTxt = '请先开户!'
          this.buyConfirmStep = 2
        }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
          this.buyConfirmTxt = '请补充资料!'
          this.buyConfirmStep = 3
        }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
          this.buyConfirmTxt = '请设置交易密码!'
          this.buyConfirmStep = 4
        }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
          this.buyConfirmTxt = '请先完成风险测评!'
          this.buyConfirmStep = 5
        }else if(userInfo.riskLevel < fund.ofundRisklevel){
          this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
          this.buyConfirmStep = 6
        }
      }
      this.preBuyFund = fund
      if(!this.buyConfirmStep) {
        this.buyConfirm()
        return
      }      
      this.showBuyConfirm = true
		},
    /** 购买基金确认 */
    buyConfirm() {
      this.showBuyConfirm = false
      if(this.buyConfirmStep === 1) { 
        this.$router.push({ name: "Login" })
        return
      }
      if(this.buyConfirmStep === 2) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 3) { 
        this.$router.push({ name: "PersonalDataSupply" });
        return
      }
      if(this.buyConfirmStep === 4) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 5) { 
        this.$router.push({ name: "RiskAssessment" });
        return
      }
      this.$router.push({name:'ProcessPurchase',params:{fundCode:this.preBuyFund.fundCode,newfund:this.preBuyFund.newfund}})
    }
  }
}
</script>


<style lang="less" scoped>
  @orange:#CE9B63;
  @red: #D43F3F;
  @green:#55aa00;
  .gray{ color:#BDC0CB !important;}
  .red{color:@red;}
  .green{ color: @green;}
  .theme-fund{ background: #F3F4F6;}
  .list-box{ background: url(../../../static/img/banner/theme-fund.png) no-repeat left top #fff;}
  .theme-block{ padding:0 30px;}
  .theme-title{ padding:35px 0; font-size:18px; align-items: flex-end;
    .icon{ color:#EFC9A3; font-size:22px; margin-right: 10px;}
    .more{ font-size:14px;}
  }
  .theme-content{ justify-content: flex-start;}
  .item{ width:20%; box-shadow: 0 0 10px #ddd; font-size: 15px; border-bottom: 2px solid @orange; padding:25px 20px; margin: 0 10px;
    .title{ font-size: 25px; height:1em; overflow: hidden; display: block; line-height: 1em;}
    .code{font-weight: bold; line-height: 4em;}
    .rate{font:bold 38px "microsoft yahei"; margin:30px auto 14px;}
    .buy-box{ margin-top:65px; font-weight: bold;
      .btn-buy{color:@orange; font-size:16px;}
      .icon{color:@orange; font-size:12px; margin-left:6px;}
    }
  }
  .view-all{ padding:70px; text-align: center;
    .go-all{color:#fff; background: @orange; padding:20px 80px;}
  }
</style>
